<template>
  <div v-loading="mailDetail.loading" class="mail_detail">
    <p class="mail_detail_title"></p>
    <div
      :class="{ mail_detail_content_small: isSmall }"
      class="mail_detail_content"
    >
      <el-scrollbar style="height: 100%">
        <div class="mail_detail_content_header flex-y-c">
          <img
            :src="
              `http://file.aismile.cn/express/${mailDetail.companyCode ||
                'ZWKD'}.png`
            "
            alt=""
            class="mail_detail_content_header_img mr20"
          />
          <div class="mail_detail_content_header_right flex-y-c">
            <div>
              <p class="mail_name">{{ mailDetail.logisticsCompany }}</p>
              <p class="mt8 mail_number">
                {{ $t("casesDetail.casesChildren.viewLogistics.ydh") }}：{{
                  mailDetail.logisticsNo
                }}
              </p>
            </div>
            <div class="mail_detail_content_header_right_right">
              <p class="mail_detail_content_header_right_right_state">
                {{ statusObj[+mailDetail.kdnStatus] }}
              </p>
              <div
                class="fz12 w100 flex-x-y-c mail_detail_content_header_right_right_time"
              >
                <p>{{ mailDetail.updateDatetime }}</p>
              </div>
              <div class="flex-y-c mt10">
                <p
                  :title="mailDetail.senderProvinceName"
                  class="c-333 txt-ellipsis fz14"
                  style="width: 0.84rem;"
                >
                  {{ mailDetail.senderProvinceName }}
                </p>
                <img
                  alt=""
                  class="mail_detail_content_header_right_right_to"
                  src="../../../common/imgs/expressage_birdie/to.png"
                />
                <p
                  :title="mailDetail.receiverProvinceName"
                  class="c-333 txt-ellipsis fz14"
                  style="width: 0.84rem;"
                >
                  {{ mailDetail.receiverProvinceName }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="dotted_line"></div>
        <div class="mail_detail_content_subscribe">
          <div class="flex-y-c w100">
            <p
              :style="language === 'en_US' ? 'width: 60%' : 'width: 50%'"
              class="fz14 txt-ellipsis"
            >
              <span style="color: #666666">{{ $t("birdie.xdsj") }}：</span>
              <span style="color: #333333">{{
                mailDetail.createDatetime
              }}</span>
            </p>
            <p
              :style="language === 'en_US' ? 'width: 40%' : 'width: 50%'"
              class="fz14 txt-ellipsis"
            >
              <span style="color: #666666">{{ $t("birdie.qjhm") }}：</span>
              <span style="color: #007AFF">{{ mailDetail.pickupCode }}</span>
            </p>
          </div>
          <div class="flex-y-c mt4 w100">
            <p
              :style="language === 'en_US' ? 'width: 60%' : 'width: 50%'"
              class="txt-ellipsis"
            >
              <span style="color: #666666">{{ $t("birdie.yysmsj") }}：</span>
              <span style="color: #333333">{{
                mailDetail.pickupDatetime
              }}</span>
            </p>
            <p
              :style="language === 'en_US' ? 'width: 40%' : 'width: 50%'"
              :title="`${mailDetail.personName} - ${mailDetail.personTel}`"
              class="txt-ellipsis"
            >
              <span style="color: #666666">{{ $t("birdie.kdxx") }}：</span>
              <span style="color: #333333"
                >{{ mailDetail.personName }} - {{ mailDetail.personTel }}</span
              >
            </p>
          </div>
        </div>
        <div class="wire"></div>
        <div class="mail_detail_content_detail flex-y-c">
          <div class="mail_detail_content_detail_left">
            <img alt="" src="../../../common/imgs/expressage_birdie/send.png" />
            <div>
              <div class="flex-y-c">
                <p class="organization txt-ellipsis">
                  {{ mailDetail.senderName }}
                </p>
                <p class="iphone_number txt-ellipsis">
                  {{ mailDetail.senderMobile }}
                </p>
              </div>
              <p
                :title="
                  mailDetail.senderProvinceName +
                    mailDetail.senderCityName +
                    mailDetail.senderAreaName
                "
                class="provinces mt8 txt-ellipsis"
              >
                {{
                  mailDetail.senderProvinceName +
                    mailDetail.senderCityName +
                    mailDetail.senderAreaName
                }}
              </p>
              <p class="mt4 txt-ellipsis2 location">
                {{ mailDetail.senderAddress }}
              </p>
            </div>
          </div>
          <div class="segmentation"></div>
          <div class="mail_detail_content_detail_left">
            <img alt="" src="../../../common/imgs/expressage_birdie/put.png" />
            <div>
              <div class="flex-y-c">
                <p class="organization txt-ellipsis">
                  {{ mailDetail.receiverName }}
                </p>
                <p class="iphone_number txt-ellipsis">
                  {{ mailDetail.receiverMobile }}
                </p>
              </div>
              <p
                :title="
                  mailDetail.receiverProvinceName +
                    mailDetail.receiverCityName +
                    mailDetail.receiverAreaName
                "
                class="provinces mt8 txt-ellipsis"
              >
                {{
                  mailDetail.receiverProvinceName +
                    mailDetail.receiverCityName +
                    mailDetail.receiverAreaName
                }}
              </p>
              <p class="mt4 txt-ellipsis2 location">
                {{ mailDetail.receiverAddress }}
              </p>
            </div>
          </div>
        </div>
        <p class="mail_message">{{ $t("birdie.wlxx") }}：</p>
        <div class="mail_message_detail">
          <template v-if="logisticsList && logisticsList.length > 0">
            <div
              v-for="(item, index) in logisticsList"
              :key="index"
              class="mail_message_detail_item"
            >
              <p class="mail_message_detail_item_left">
                {{ get(item, "value[0].acceptTime") }}
              </p>
              <div class="mail_message_detail_item_right">
                <p class="mail_message_detail_state">{{ item.key }}</p>
                <div
                  v-for="(it, itx) in item.value"
                  :key="itx"
                  :class="{
                    mt10: itx === 0 && item.value && item.value.length > 1
                  }"
                  class="mail_message_detail_describe"
                >
                  {{ it.acceptStation }}
                  <p
                    v-if="item.value && item.value.length > 1"
                    class="mail_message_detail_describe_time"
                  >
                    {{ it.acceptTime }}
                  </p>
                </div>
                <div
                  class="mail_message_detail_item_round mail_message_detail_item_round_red"
                ></div>
              </div>
            </div>
          </template>
          <div
            v-else
            :class="{ mail_detail_empty_small: isSmall }"
            class="mail_detail_empty"
          >
            <img
              alt=""
              src="../../../common/imgs/expressage_birdie/mail_detail_empty.png"
            />
            <p class="tac">{{ $t("birdie.nzshmywlxx") }}~</p>
          </div>
        </div>
      </el-scrollbar>
      <img
        alt=""
        class="mail_detail_content_bg"
        src="../../../common/imgs/expressage_birdie/confirm_bg.png"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { logisticsDetail, logisticsCurrent } from "common/api/cases";
import { getDictList } from "common/api/public";
import { get, each } from "lodash";

export default {
  data() {
    return {
      isSmall: false,
      mailDetail: {
        loading: false
      },
      logisticsList: [],
      statusObj: {}
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    ...mapGetters({})
  },

  methods: {
    ...mapActions({}),
    get,
    getLogisticsCurrent() {
      let { companyCode, mailId } = this.$route.query;
      logisticsCurrent({
        companyCode,
        logisticsId: mailId
      }).then(data => {
        if (Array.isArray(data.traces)) {
          const obj = {};
          const list = [];
          data.traces.reverse().forEach(item => {
            if (!obj[item.action]) {
              obj[item.action] = [item];
              list.push({
                key: this.logisticsStatus[item.action],
                value: obj[item.action]
              });
            } else {
              obj[item.action].push(item);
            }
          });
          this.logisticsList = list;
        }
      });
    },
    async getDetail() {
      if (this.mailDetail.loading === true) return;
      this.mailDetail.loading = true;
      this.getStatus();
      await getDictList("kdn_logistics_statusex").then(data => {
        const obj = {};
        data.forEach(item => {
          obj[item.key] = item.value;
        });
        this.logisticsStatus = obj;
        this.getLogisticsCurrent();
      });
      return await logisticsDetail(this.$route.query.mailId)
        .then(data => {
          this.mailDetail = data;
          this.mailDetail.loading = false;
        })
        .catch(() => {
          this.mailDetail.loading = false;
        });
    },
    getStatus() {
      getDictList("logistics_kdn_status").then(data => {
        each(data, item => {
          this.statusObj[+item.key] = item.value;
        });
      });
    }
  },

  created() {
    this.$emit("getDiaTitle", "　　　");
    this.isSmall = window.screen.height < 1000;
    this.getDetail();
  }
};
</script>

<style lang="scss" scoped>
.mail_detail {
  height: 100%;
  padding: 0 30px;
  position: relative;
  top: -30px;

  .mail_detail_title {
    color: #333333;
    font-size: 16px;
    line-height: 22px;
    height: 22px;
  }

  .mail_detail_product {
    color: #999999;
    line-height: 20px;
    height: 20px;
    font-size: 14px;
    margin-top: 5px;
  }

  .mail_detail_content {
    margin-top: 10px;
    height: 666px;
    background: #ffffff;
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    padding: 20px;
    position: relative;

    .dotted_line {
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed #979797;
      margin-top: 20px;
    }

    .mail_detail_content_bg {
      display: block;
      width: 100%;
      height: 16px;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .mail_detail_content_header {
      margin-right: 20px;

      .mail_detail_content_header_img {
        display: block;
        width: 64px;
        height: 64px;
      }

      .mail_detail_content_header_right {
        width: 100%;
        margin-left: 10px;
        position: relative;

        .mail_name {
          max-width: 1.2rem;
          height: 22px;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .mail_number {
          max-width: 2.3rem;
          height: 20px;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .mail_detail_content_header_right_right {
          width: 1.92rem;
          height: 65px;
          position: absolute;
          right: 0;
          top: 0;

          .mail_detail_content_header_right_right_to {
            display: block;
            width: 38px;
            height: 8px;
            margin-left: 10px;
            margin-right: 10px;
          }

          .mail_detail_content_header_right_right_state {
            width: 100%;
            text-align: center;
            color: #ed4027;
            line-height: 20px;
            height: 20px;
          }

          .mail_detail_content_header_right_right_time {
            height: 16px;
            line-height: 16px;
          }
        }
      }
    }

    .mail_detail_content_subscribe {
      margin-top: 19px;
    }

    .wire {
      width: 100%;
      height: 1px;
      background: #e1e1e1;
      margin-top: 20px;
    }

    .mail_detail_content_detail {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .segmentation {
        width: 1px;
        height: 73px;
        background: #e1e1e1;
        position: absolute;
        left: 45%;
      }

      .mail_detail_content_detail_left {
        width: 270px;
        display: flex;

        img {
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        .organization {
          width: 0.84rem;
          line-height: 20px;
          height: 20px;
          color: #333333;
        }

        .iphone_number {
          max-width: 1.05rem;
          line-height: 20px;
          height: 20px;
          color: #333333;
          margin-left: 5px;
        }

        .provinces {
          width: 2rem;
          height: 20px;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }

        .location {
          width: 2rem;
          line-height: 20px;
          color: #666666;
        }
      }
    }

    .mail_message {
      margin-top: 20px;
      color: #333333;
      font-size: 16px;
    }

    .mail_message_detail {
      width: 80%;
      margin-left: 53px;
      padding-top: 16px;
      position: relative;

      .mail_detail_empty {
        position: absolute;
        left: 50%;
        top: 136px;
        transform: translateX(-50%);

        img {
          display: block;
          width: 1.96rem;
        }
      }

      .mail_detail_empty_small {
        top: 40px !important;
      }

      .mail_message_detail_item {
        width: 100%;
        position: relative;
        display: flex;

        .mail_message_detail_item_left {
          color: #333333;
          min-width: 1.42rem;
          margin-right: 27px;
        }

        .mail_message_detail_item_right {
          flex: 1;
          border-left: 1px #bfbfbf dashed;
          padding-left: 28px;
          padding-bottom: 24px;
          position: relative;

          .mail_message_detail_state {
            font-size: 14px;
            color: #333333;
          }

          .mail_message_detail_describe {
            width: 260px;
            font-size: 12px;
            color: #666666;
            line-height: 16px;
            margin-top: 8px;
            position: relative;

            .mail_message_detail_describe_time {
              font-size: 14px;
              color: #333333;
              position: absolute;
              left: -198px;
              top: 0;
            }
          }

          .mail_message_detail_item_round {
            width: 10px;
            height: 10px;
            background: #bfbfbf;
            border-radius: 50%;
            position: absolute;
            left: -5px;
            top: 0;
          }

          .mail_message_detail_item_round_red {
            background: #ed4027 !important;
          }
        }
      }
    }
  }

  .mail_detail_content_small {
    height: 740px !important;
  }
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
