<template>
  <div class="organization_code">
    <div class="organization_code_header flex-y-c">
      <p class="organization_code_header_text">{{$t('birdie.xzjg')}}：</p>
      <el-select v-model="value" :placeholder="$t('casesDetail.casesChildren.common.qxz')">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <div class="save_btn flex-x-y-c ml30 curp">保存图片</div>
      <div class="print_btn flex-x-y-c ml20 curp">{{$t('birdie.dy')}}</div>
    </div>
    <div class="wire"></div>
    <div class="img_content" :class="{img_content_small: !windowHeight}">
      <div class="img_content_header flex-x-y-c">
        <div class="img_content_header_init mr20"></div>
        <p class="img_content_header_text">{{$t('birdie.ylkjmfjs')}}</p>
        <div class="img_content_header_init ml20"></div>
      </div>
      <p class="img_content_clinic">杭州诊所</p>
      <div class="img_content_code" :class="{img_content_code_small: !windowHeight}">
        <img class="img_content_code_bg" src="../../../common/imgs/expressage_birdie/code_bg.png" alt="">
        <p class="img_content_code_text">{{$t('birdie.smjsmx')}}</p>
      </div>

    </div>
    <div class="organization_code_footer">
      <div class="organization_code_footer_content flex-y-c">
        <div class="organization_code_footer_content_item">
          <img src="../../../common/imgs/expressage_birdie/code_one.png" alt="">
          <p class="organization_code_footer_content_item_p1">{{$t('birdie.smdkxd')}}</p>
        </div>
        <div style="width: 146px;" class="organization_code_footer_content_item">
          <img src="../../../common/imgs/expressage_birdie/code_two.png" alt="">
          <p>{{$t('birdie.gjmxgx')}}</p>
        </div>
        <div class="organization_code_footer_content_item">
          <img src="../../../common/imgs/expressage_birdie/code_three.png" alt="">
          <p>{{$t('birdie.xdwcdd')}}</p>
        </div>
      </div>
      <img class="code_logo" src="../../../common/imgs/expressage_birdie/code_logo.png" alt="">
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    data() {
      return {
        options: [
          {
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }
        ],
        value: '',
        windowHeight: ''
      };
    },
    computed: {
      ...mapGetters({}),

    },

    methods: {
      ...mapActions({}),

    },

    created() {
      this.$emit('getDiaTitle', '');
      this.windowHeight = window.screen.height > 1000;
    }
  }
</script>

<style lang="scss" scoped>
  .organization_code{
    height: 100%;
    padding: 0 30px;
    position: relative;
    .organization_code_header{
      height: 0.4rem;
      .organization_code_header_text{
        color: #333333;
        font-size: 16px;
      }
      .save_btn{
        width: 80px;
        height: 36px;
        background: #FEC111;
        border-radius: 3px;
        color: white;
      }
      .print_btn{
        width: 80px;
        height: 36px;
        background: #FEC111;
        border-radius: 3px;
        color: white;
      }
    }
    .wire{
      width: 100%;
      height: 1px;
      background: #E1E1E1;
      margin-top: 20px;
    }
    .img_content{
      margin-top: 80px;
      width: 328px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .img_content_header{
        width: 100%;
        .img_content_header_init{
          width: 24px;
          height: 2px;
          background: #FEC111;
        }
        .img_content_header_text{
          font-size: 24px;
          color: #333333;
        }
      }
      .img_content_clinic{
        width: 100%;
        text-align: center;
        margin-top: 14px;
        color: #333333;
        font-size: 18px;
      }
      .img_content_code{
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 8px;
        margin-top: 20px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .img_content_code_bg{
          display: block;
          width: 100%;
          height: 100%;
        }
        .img_content_code_text{
          width: 100%;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 10px;
          font-size: 16px;
          color: white;
        }
      }
      .img_content_code_small{
        width: 1.88rem;
        height: 1.88rem !important;
      }
    }
    .img_content_small{
      margin-top: 20px !important;
    }
    .organization_code_footer{
      width: 100%;
      height: 2.56rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background: url("../../../common/imgs/expressage_birdie/code_footer_bg.png") no-repeat;
      .organization_code_footer_content{
        width: 484px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 35px;
        .organization_code_footer_content_item{
          width: 114px;
          height: 150px;
          display: flex;
          align-items: center;
          flex-flow: column;
          position: relative;
          img{
            display: block;
            width: 88px;
            height: 100px;
            position: absolute;
            top: 0;
          }
          p{
            width: 100%;
            line-height: 20px;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
          }
          .organization_code_footer_content_item_p1{
            bottom: 19px;
          }
        }
      }
      .code_logo{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 11px;
      }
    }
  }
</style>