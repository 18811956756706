<template>
  <div v-loading="orderDetail.loading" class="place_order">
    <p class="place_order_title">{{$t('birdie.wlsmzq')}}</p>
    <div v-infinite-scroll="load" ref="place_order_content" style="overflow:auto"
         class="place_order_content infinite-list" :class="{place_order_content_small: isSmall}">
      <template v-if="orderDetail.list && orderDetail.list.length > 0">
        <div @click.stop="jumpPage({
                 indexType: 'detail',
                 mailId: item.id,
                 companyCode: item.companyCode,
                 logisticsNo: item.logisticsNo,
                 })" v-for="(item, index) in orderDetail.list" :key="index" class="order_item mt4 curp">
          <div class="flex-x-between">
            <div class="flex-y-c">
              <p class="c-333">{{moment(item.createDatetime).format("YYYY-MM-DD")}}</p>
              <p class="ml32 c-333">{{item.logisticsCaseNum}}个{{$t('birdie.mx')}}</p>
            </div>
            <p class="main_theme_color_red fz14">{{statusObj[+item.kdnStatus]}}</p>
          </div>
          <div v-for="(it, itx) in item.logisticsCaseList" :key="itx"
                class="model flex-y-c curp">
            <div class="flex-y-c">
              <img class="user_img" :src="PIC_PREFIX + it.userPhoto" alt="">
              <p class="ml10 user_name">{{it.userName}}</p>
            </div>
            <div class="case_number">{{it.caseNumber}}</div>
            <div class="create_time">{{moment(it.caseCreateDatetime).format("YYYY-MM-DD")}}</div>
          </div>
          <div class="order_item_footer">
            <p class="order_item_footer_left txt-ellipsis">
              {{$t('casesDetail.casesChildren.viewLogistics.ydh')}}：{{item.logisticsNo}}
            </p>
            <p class="order_item_footer_center">{{$t('birdie.qjm')}}：<span class="fz14" style="color:#007AFF;">{{item.pickupCode}}</span></p>
            <div v-if="[1,2,3,4].includes(+item.kdnStatus)"
                 :style="language=== 'en_US' ? 'width: 1.1rem' : ''"
                 class="order_item_btn order_item_footer_right flex-x-y-c curp"
                 @click.stop.prevent="openCancelDia(item.id)">{{$t('birdie.qxjs')}}</div>
          </div>
        </div>
      </template>
      <div class="place_empty" v-else>
        <img src="../../../common/imgs/expressage_birdie/place_empty.png" alt="">
        <p class="main_theme_color_666 fz14 mt24">{{$t('birdie.nzshmyyxdmx')}}</p>
      </div>
    </div>
    <div class="place_order_footer"></div>
    <el-dialog
      class="core_dia"
      width="454px"
      :show-close="false"
      :visible.sync="innerVisible"
      append-to-body>
      <div class="core_dia_content">
        <img class="confirm_success" src="../../../common/imgs/expressage_birdie/place_warn.png" alt="">
        <p class="core_dia_content_h1 c-333 fz18">{{$t('common.message.sfjx')}}</p>
        <p class="core_dia_content_h2">{{$t('birdie.qxjsh')}}</p>

        <div class="core_dia_content_footer">
          <div class="cancel flex-x-y-c curp" @click="innerVisible = false">{{$t('common.common.qx')}}</div>
          <div class="confirm flex-x-y-c curp" @click="pickupCancel">{{$t('birdie.qdqxjs')}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapState} from 'vuex';
  import {pickBy, extend, get, each} from 'lodash';
  import {
    PublicLogisticsPage,
    PublicPickupCancel
  } from 'common/api/cases';
  import {PIC_PREFIX} from 'common/js/config';
  import moment from 'moment'
  import {notifyMsg} from 'common/js/util';
  import {getDictList} from 'common/api/public';

  export default {
    data() {
      return {
        innerVisible: false,
        isSmall: false,
        PIC_PREFIX,
        orderDetail:{
          list: [],
          loading: false
        },
        pickupCancelId: '',
        number: 1,
        statusObj: {}
      };
    },
    computed: {
      ...mapState({
        language: state => state.language,
      }),
      ...mapGetters({}),
      params(){
        let {orderPageNum,orderPageSize} = this.$route.query;
        return {
          pageNum: orderPageNum || 1,
          pageSize: orderPageSize || 10,
          doctorId: localStorage.getItem('userId'),
          pageType: 'deliver_model',
          type: '1',
          way: '2'
        }
      }

    },

    methods: {
      ...mapActions({}),
      get,
      moment,
      load(){
        this.jumpPage({orderPageSize: +this.params.pageSize + (this.number === 1 ? 0 : 10), re: true});
        this.number += 1;
        this.getDetail()
      },
      openCancelDia(id){
        this.innerVisible = true;
        this.pickupCancelId = id;
      },
      pickupCancel(){
        this.orderDetail.loading = true;
        this.innerVisible = false;
        PublicPickupCancel({id: this.pickupCancelId}).then(() => {
          this.orderDetail.loading = false;
          notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.common.czcg'));
          this.getDetail()
        }).catch(() => {
          this.orderDetail.loading = false;
        })
      },
      jumpPage(arg){
        let {re = false} = arg;
        let route = this.$route;
        this.$router[re ? 'replace' : 'push'](pickBy({
          path: route.path,
          query: extend({}, route.query, arg)
        }));
      },
      getDetail(){
        if(this.orderDetail.loading === true) return;
        this.orderDetail.loading = true;
        PublicLogisticsPage(this.params).then((data) => {
          this.orderDetail.list = data.list;
          this.orderDetail.loading = false;
        }).catch(() => {
          this.orderDetail.loading = false;
        })
      },
      getStatus(){
        getDictList('logistics_kdn_status').then((data) => {
          each(data, (item) => {
            this.statusObj[+item.key] = item.value;
          })
        })
      }
    },
    created() {
      this.$emit('getDiaTitle', this.$t('birdie.yxdmx'));
      this.isSmall = window.screen.height < 1000;
      this.getStatus();
    }
  }
</script>

<style lang="scss" scoped>
  .place_order{
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 10px 0 0 0;
    position: relative;
    .place_order_title{
      color: #333333;
      font-size: 12px;
      line-height: 16px;
      height: 32px;
      margin: 0 30px;
    }
    .place_order_content{
      height: 6.59rem;
      margin-top: 0.18rem;
      padding: 0 0 0 20px;
      position: relative;
      .place_empty{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img{
          display: block;
          width: 204px;
        }
      }
       .order_item{
          margin: 0 0.3rem 0.1rem 0.1rem;
          box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
          border-radius: 8px;
          padding: 20px 20px 10px 20px;
          position: relative;
          .model{
            width: 100%;
            height: 52px;
            background: #F4F4F4;
            border-radius: 4px;
            margin-top: 10px;
            padding-left: 16px;
            .user_img{
              display: block;
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }
            .user_name{
              width: 1rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
              line-height: 20px;
              color: $main_theme_color_333;
            }
            .case_number{
              width: 1.1rem;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 20px;
              font-size: 14px;
              color: $main_theme_color_333;
              margin-left: 30px;
            }
            .create_time{
              color: $main_theme_color_333;
              width: 1rem;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 20px;
              font-size: 14px;
              margin-left: 40px;
            }
          }
          .order_item_btn{
            width: 88px;
            height: 28px;
            background: rgba(254, 193, 17, 0.06);
            border-radius: 3px;
            border: 1px solid $main_theme_color;
            color: $main_theme_color;
            font-size: 14px;
            text-align: center;
            line-height: 0.28rem;
          }
          .order_item_footer{
            margin-top: 20px;
            width: 100%;
            height: 28px;
            line-height: 28px;
            display: flex;
            align-items: center;
            position: relative;
            .order_item_footer_left{
              width: 181px;
              color: #333333;
              position: absolute;
              left: 0;
              top: 0;
            }
            .order_item_footer_center{
              color: #333333;
              position: absolute;
              left: 260px;
              top: 0;
            }
            .order_item_footer_right{
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }
    }
    .place_order_content_small{
      height: 700px;
    }
    .place_order_footer{
      width: 100%;
      height: 30px;
      background: #FFFFFF;
      box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.12);
      border-radius: 0 0 8px 8px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  /deep/  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .core_dia{
    top: 200px !important;
    padding: 0 !important;
    .core_dia_content{
      height: 270px;
      position: relative;
      .confirm_success{
        display: block;
        width: 80px;
        height: 80px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 33px;
      }
      .core_dia_content_h1{
        width: 100%;
        text-align: center;
        position: absolute;
        top: 136px;
      }
      .core_dia_content_h2{
        width: 100%;
        position: absolute;
        top: 166px;
        color: #999999;
        font-size: 14px;
        text-align: center;
      }
      .core_dia_content_footer{
        position: absolute;
        bottom: 24px;
        display: flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .cancel{
          width: 138px;
          height: 36px;
          background: #FFFFFF;
          border-radius: 4px;
          font-size: 14px;
          border: 1px solid #BBBBBB;
          color: #333333;
        }
        .confirm{
          margin-left: 32px;
          width: 138px;
          height: 36px;
          font-size: 14px;
          background: #Fcc80e;
          border-radius: 4px;
          color: #333333;
        }
      }
    }
  }
  /deep/ .el-dialog__header{
    display: none;
  }
  /deep/ .el-dialog__body{
    padding: 0 !important;
  }
</style>