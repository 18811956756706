var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:([_vm.clinic.loading, _vm.personage.state].includes('loading')),expression:"[clinic.loading, personage.state].includes('loading')"}],staticClass:"local_wrap"},[(_vm.caseDetail['selfDeliverFlag'] == 1)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("birdie.qxzjjdz"))+" ")]):_vm._e(),_c('div',{staticClass:"local_header"},[_c('div',{staticClass:"identification"}),_c('p',{staticClass:"local_header_text"},[_vm._v(_vm._s(_vm.$t("birdie.dqjgdz")))])]),_c('div',{staticClass:"header_item"},[_c('div',{staticClass:"local_item"},[_c('div',{staticClass:"local_item_left"},[_c('p',{staticClass:"local_item_left_provinces"},[_vm._v(" "+_vm._s(_vm.clinic.provinceName)+"-"+_vm._s(_vm.clinic.cityName)+"-"+_vm._s(_vm.clinic.areaName)+" ")]),_c('p',{staticClass:"local_item_left_site txt-ellipsis"},[_vm._v(_vm._s(_vm.clinic.address))]),_c('p',{staticClass:"local_item_left_iphone_number"},[_vm._v(" "+_vm._s(_vm.clinic.contactWay == "2" ? _vm.clinic.phoneArea + "-" + _vm.clinic.phone : _vm.clinic.phone)+" ")])]),_c('div',{staticClass:"local_item_right"},[_c('div',{staticClass:"local_item_right_btn flex-x-y-c",on:{"click":function($event){return _vm.jumpPage({
              indexType: 'model',
              relevanceId: _vm.clinic.id,
              clickClinic: true,
            })}}},[_vm._v(" "+_vm._s(_vm.$t("birdie.xz"))+" ")]),_c('p',{staticClass:"local_item_right_text curp",class:{ local_item_right_text_lang: _vm.isELang },on:{"click":function($event){return _vm.jumpPage({
              indexType: 'location',
              type: 1,
              editId: _vm.clinic.id,
            })}}},[_vm._v(" "+_vm._s(_vm.$t("birdie.bj"))+" ")])])])]),_c('p',{staticClass:"organization_init ml30"},[_vm._v(_vm._s(_vm.$t("birdie.gtxd")))]),_c('div',{staticClass:"wire"}),_c('div',{staticClass:"personage_header"},[_c('div',{staticClass:"flex-y-c"},[_c('div',{staticClass:"identification"}),_c('p',{staticClass:"personage_header_text main_theme_color_333"},[_vm._v(" "+_vm._s(_vm.$t("birdie.grdz"))+" ")])]),_c('div',{staticClass:"flex-y-c curp",on:{"click":function($event){return _vm.jumpPage({
          indexType: 'location',
          type: 2,
        })}}},[_c('img',{attrs:{"src":require("../../../common/imgs/expressage_birdie/local.png"),"alt":""}}),_c('p',{staticClass:"new_add_personage"},[_vm._v(_vm._s(_vm.$t("birdie.xzgrdz")))])])]),_c('div',{staticClass:"personage_content",style:({ height: _vm.scrollbarHei + 'rem', paddingBottom: '0.56rem' })},[_c('el-scrollbar',{staticStyle:{"height":"100%"}},_vm._l((_vm.personage.list),function(item,index){return _c('div',{key:index,staticClass:"personage_item"},[_c('div',{staticClass:"local_item_left"},[_c('p',{staticClass:"local_item_left_provinces"},[_vm._v(" "+_vm._s(item.provinceName)+"-"+_vm._s(item.cityName)+"-"+_vm._s(item.areaName)+" ")]),_c('p',{staticClass:"local_item_left_site txt-ellipsis"},[_vm._v(_vm._s(item.address))]),_c('p',{staticClass:"local_item_left_iphone_number"},[_vm._v(" "+_vm._s(item.contactWay == 1 ? item.phone : ((item.phoneArea) + "-" + (item.phone)))+" ")])]),_c('div',{staticClass:"local_item_right"},[_c('div',{staticClass:"local_item_right_btn flex-x-y-c",on:{"click":function($event){return _vm.jumpPage({
                indexType: 'model',
                relevanceId: item.id,
                clickClinic: true,
              })}}},[_vm._v(" "+_vm._s(_vm.$t("birdie.xz"))+" ")]),_c('p',{staticClass:"local_item_right_text curp",class:{ local_item_right_text_lang: _vm.isELang },on:{"click":function($event){return _vm.jumpPage({
                indexType: 'location',
                type: '3',
                editId: item.id,
              })}}},[_vm._v(" "+_vm._s(_vm.$t("birdie.bj"))+" ")])])])}),0)],1),_c('div',{staticClass:"local_footer"})])}
var staticRenderFns = []

export { render, staticRenderFns }