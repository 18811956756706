<template>
  <div class="place_order_await">
    <div class="circle">
      <img :src="imgUrl" alt="">
      <p>{{$t('birdie.xdddz')}}</p>
      <div class="number">
        {{number}}S
      </div>
    </div>

    <el-dialog
      class="core_dia"
      width="454px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="innerVisible"
      append-to-body>
      <div class="core_dia_content">
        <img :class="{confirm_err: orderDefeated}" class="confirm_success" :src="orderImg" alt="">
        <p class="core_dia_content_h1 c-333 fz18">{{orderDefeated ? $t('birdie.xdsb') : $t('birdie.ydwcdd')}}</p>
        <p v-if="!orderDefeated" class="core_dia_content_h2">{{$t('birdie.nkycx')}}</p>

        <div class="core_dia_content_footer">
          <div class="cancel flex-x-y-c curp" @click="backIndex()">{{$t('common.common.qx')}}</div>
          <div @click="lookOrReSetOrder" class="confirm flex-x-y-c curp">
            {{orderDefeated ? $t('birdie.cxxd') : $t('birdie.ckyxdmx')}}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {PublicLogisticsDetailStatus} from 'common/api/cases';

  export default {
    data() {
      return {
        number: 15,
        innerVisible: false,
        orderDefeated: false
      };
    },
    computed: {
      ...mapGetters({}),
      imgUrl(){
        return this.number === 0 ? require('../../../common/imgs/expressage_birdie/reciprocal_init.png')
          : require('../../../common/imgs/expressage_birdie/reciprocal.gif')
      },
      orderImg(){
        return this.orderDefeated ? require('../../../common/imgs/expressage_birdie/confirm_err.png')
          : require('../../../common/imgs/expressage_birdie/confirm_success.png')
      }
    },

    methods: {
      ...mapActions({}),
      lookOrReSetOrder(){
        let route = this.$route;
        if(this.orderDefeated){
          this.backIndex();
        }else{
          this.$router.replace({
            path: route.path,
            query: {indexType: 'place'}
          })
        }
      },
      backIndex(){
        let route = this.$route;
        this.$router.replace({
          path: route.path,
          query: {indexType: ''}
        })
      },
      getLogisticsDetailStatus(){
        PublicLogisticsDetailStatus(this.$route.query.orderCode).then((data) => {
          if([2,3].includes(+data.status)){
            return this.orderDefeated = true;
          }
          this.innerVisible = true;
        })
      }
    },

    created() {
      this.$emit('getDiaTitle', '　');
      let fn = setInterval(() => {
        if(this.number === 0) {
          this.getLogisticsDetailStatus();
          return clearInterval(fn)
        }
        this.number --;
      }, 1000)
    }
  }
</script>

<style lang="scss" scoped>
  .place_order_await{
    width: 100%;
    height: calc(100% - 56px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle{
      display: flex;
      flex-flow: column;
      align-items: center;
      position: relative;
      img{
        display: block;
        width: 148px;
        height: 148px;
      }
      p{
        margin-top: 24px;
        color: #999999;
        font-size: 16px;
      }
      .number{
        position: absolute;
        top: 34%;
        font-size: 28px;
      }
    }
  }
  .core_dia{
    top: 200px !important;
    padding: 0 !important;
    .core_dia_content{
      height: 270px;
      position: relative;
      .confirm_success{
        display: block;
        width: 134px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 33px;
      }
      .confirm_err{
        width: 81px !important;
      }
      .core_dia_content_h1{
        width: 100%;
        text-align: center;
        position: absolute;
        top: 136px;
      }
      .core_dia_content_h2{
        width: 100%;
        position: absolute;
        top: 166px;
        color: #999999;
        font-size: 14px;
        text-align: center;
      }
      .core_dia_content_footer{
        position: absolute;
        bottom: 24px;
        display: flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .cancel{
          width: 138px;
          height: 36px;
          background: #FFFFFF;
          font-size: 14px;
          border-radius: 4px;
          border: 1px solid #BBBBBB;
          color: #333;
        }
        .confirm{
          margin-left: 32px;
          width: 138px;
          height: 36px;
          background: #fcc80e;
          font-size: 14px;
          border-radius: 4px;
          color: #333;
        }
      }
    }
  }

</style>