<template>
  <div class="the_delivery">
    <div class="title">{{ $t("cases.theDelivery.txkdxx") }}</div>
    <div class="scheduling_con">
      <div class="s_con_form">
        <div class="f_single">
          <div
            :style="lang === 'en_US' ? 'width: 1.4rem' : ''"
            class="f_left required"
          >
            {{ $t("cases.theDelivery.sjr") }}
          </div>
          <div class="f_right">
            <input
              type="text"
              v-model="params.receiverName"
              :placeholder="$t('cases.theDelivery.qtxsjr')"
              disabled
            />
          </div>
        </div>
        <div class="f_single">
          <div
            :style="lang === 'en_US' ? 'width: 1.4rem' : ''"
            class="f_left required"
          >
            {{ $t("cases.theDelivery.sjrsjh") }}
          </div>
          <div class="f_right">
            <input
              type="text"
              :value="
                params.receiverContactWay === '2'
                  ? params.receiverPhoneArea + ' - ' + params.receiverMobile
                  : params.receiverMobile
              "
              :placeholder="$t('cases.theDelivery.qtxsjh')"
              disabled
            />
          </div>
        </div>
        <div class="f_single">
          <div
            :style="lang === 'en_US' ? 'width: 1.4rem' : ''"
            class="f_left required"
          >
            {{ $t("cases.theDelivery.dz") }}
          </div>
          <div class="f_right">
            <p class="f_r_p">
              {{ csqObj.receiverProvince || params.receiverProvinceName }}-{{
                csqObj.receiverCity || params.receiverCityName
              }}-{{ csqObj.receiverArea || params.receiverAreaName }}-{{
                params.receiverAddress
              }}
            </p>
          </div>
        </div>
        <div class="f_single">
          <div :style="lang === 'en_US' ? 'width: 1.4rem' : ''" class="f_left">
            {{ $t("cases.theDelivery.wlgs") }}
          </div>
          <div class="f_right">
            <el-select
              clearable
              style="width: 3.16rem"
              v-model="companyCode"
              :placeholder="$t('common.message.qxz')"
              @change="optionChange"
              @clear="clearCompany"
            >
              <el-option
                v-for="(item, index) in companyList"
                :key="index"
                :value="item.value"
              >
                {{ item.value }}
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="f_single">
          <div :style="lang === 'en_US' ? 'width: 1.4rem' : ''" class="f_left">
            {{ $t("cases.theDelivery.wldh") }}
          </div>
          <div class="f_right">
            <input
              class="not_dis"
              type="text"
              v-model="params.logisticsNo"
              :placeholder="$t('cases.theDelivery.qtxwldh')"
            />
          </div>
        </div>
        <div class="f_single">
          <div :style="lang === 'en_US' ? 'width: 1.4rem' : ''" class="f_left">
            {{ $t("cases.theDelivery.yjsj") }}
          </div>
          <div class="f_right" style="background-color: white">
            <el-date-picker
              v-model="params.logisticsDatetime"
              type="datetime"
              :placeholder="$t('cases.theDelivery.xzsj')"
              style="width: 3.16rem"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="appliance">{{ $t("cases.theDelivery.mxbz") }}</div>
      </div>
      <div class="foo_btn">
        <span
          :class="[
            deliveryDis ? 'foo_btn_dis' : 'main_theme_color_btn',
            'main_big_btn'
          ]"
          @click="subScheduling"
          >{{ $t("cases.theDelivery.qr") }}</span
        >
      </div>
    </div>
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import {
  createLogistics,
  editLogistics,
  logisticsDetail,
  addressDetailOss,
  PublicAddressDetailCase,
  columnDataTempRecordDetail
} from "common/api/cases";
import { getDictList } from "common/api/public";
import { getUserId, notifyMsg, formatDate, mobileValid } from "common/js/util";
import { get, filter, map } from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      params: {
        curePlanId: "",
        receiverName: "",
        receiverMobile: "",
        receiverProvince: "",
        receiverCity: "",
        receiverArea: "",
        receiverAddress: "",
        logisticsCompany: "",
        companyCode: "",
        logisticsNo: "",
        logisticsDatetime: ""
      },
      csqObj: {
        receiverProvince: "",
        receiverCity: "",
        receiverArea: ""
      },
      companyCode: "",
      companyList: [],
      companyObj: {},
      isLoading: false,
      modelDeliverFlag: "0",
      yueyaRefType: "",
      isCreated: true,
      lang: localStorage.getItem("user_lang") || "zh_CN"
    };
  },
  props: {
    refType: {
      type: String,
      default: "1"
    },
    caseDetail: {
      type: Object
    },
    isReissueFlag: {
      default: false
    },
    deliveryData: {
      type: Object
    },
    deliveryDis: {
      type: Boolean
    },
    canCreate: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean
    }
  },
  watch: {
    deliveryData: {
      handler: function(nval) {
        if (this.refType == "3") {
          this.getCaseDoneDetail();
        }
        this.refType !== "3" &&
          this.$route.query.productType !== "F" &&
          this.queryDetailOss();
      }
    },
    show: {
      handler: function(cur) {
        if (cur) {
          this.queryDetailOss();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      commonCaseDetail: "getCommonCaseDetail" //病例详情
    })
  },
  methods: {
    optionChange(e) {
      const code = filter(this.companyList, ["value", e])[0];
      this.params.companyCode = code["key"];
    },
    queryDetailOss() {
      if (Object.keys(this.deliveryData).length) {
        const {
          receiverName,
          receiverMobile,
          receiverProvince,
          receiverCity,
          receiverArea,
          receiverAddress,
          receiverPhoneArea,
          receiverContactWay,
          csqObj
        } = this.deliveryData;
        this.params = {
          ...this.params,
          receiverName,
          receiverMobile,
          receiverPhoneArea,
          receiverContactWay,
          receiverProvince,
          receiverCity,
          receiverArea,
          receiverAddress
        };
        this.csqObj = csqObj;
      }
    },
    clearCompany() {
      this.params.companyCode = "";
      this.params.logisticsCompany = "";
    },
    subScheduling() {
      if (this.deliveryDis) {
        return;
      }
      const curePlanId = this.$route.query.curePlanId;
      const { companyCode, logisticsNo, logisticsDatetime } = this.params;
      if (logisticsNo) {
        let regex = /^[0-9A-Z]+$/;
        if (!regex.test(logisticsNo)) {
          return notifyMsg(
            this,
            "warning",
            this.$t("cases.theDelivery.wldh") +
              this.$t("cases.theDelivery.zndxhsz")
          );
        }
      }
      this.params.curePlanId = curePlanId;
      this.isLoading = true;
      const params = {
        ...this.params,
        logisticsDatetime: logisticsDatetime
          ? formatDate(logisticsDatetime)
          : "",
        caseId: this.caseDetail["id"]
      };
      if (companyCode) {
        params.logisticsCompany = this.companyObj[companyCode];
        params.companyCode = companyCode;
      } else {
        params.logisticsCompany = "";
        params.companyCode = "";
      }
      // if (
      //   this.modelDeliverFlag !== "1" &&
      //   this.$route.query.productType !== "F"
      // ) {
      //   params.refType = this.refType;
      // }
      // if (this.$route.query.productType == "F") {
      //   params.refType = this.yueyaRefType;
      // }
      params.type = +this.refType - 1;
      const logisticsApi =
        this.modelDeliverFlag === "1" ? editLogistics : createLogistics;
      if (
        this.modelDeliverFlag !== "1" &&
        !params.logisticsNo &&
        !this.canCreate
      ) {
        this.isLoading = false;
        this.$emit("close");
        return;
      }
      if (this.isReissueFlag) {
        params.isReissueFlag = "1";
        if (this.$route.query.logisticsReissueId === "") {
          params.reissueId = this.$route.query.reissueId;
        } else {
          params.reissueId = this.$route.query.logisticsReissueId;
        }
      } else {
        params.isReissueFlag = "0";
      }
      logisticsApi(params)
        .then(data => {
          if (data) sessionStorage.setItem("logisticsId", data);
          this.isLoading = false;
          notifyMsg(this, "success", this.$t("cases.theDelivery.czcg"));
          if (this.modelDeliverFlag === "0") {
            this.params.id = data;
          }
          this.$emit("close");
          this.$emit("orderSuccess", 1);
          sessionStorage.removeItem("logisticsId");
          this.modelDeliverFlag = "1";
        })
        .catch(() => {
          if (this.$route.query.productType == "F") {
            this.getYueyaReceiveData();
          } else {
            this.queryDetailOss();
          }
          this.params.companyCode = "";
          this.companyCode = "";
          this.params.logisticsNo = "";
          this.params.logisticsDatetime = "";
          this.isLoading = false;
        });
    },
    removeScheduling() {
      this.$emit("removeScheduling");
    },
    getCaseDoneDetail() {
      addressDetailOss().then(data => {
        const {
          receiveUser,
          phone,
          province,
          city,
          area,
          address,
          provinceName,
          areaName,
          cityName,
          contactWay,
          phoneArea
        } = data;
        this.params = {
          ...this.params,
          receiverName: receiveUser,
          receiverMobile: phone,
          receiverContactWay: contactWay,
          receiverPhoneArea: phoneArea,
          receiverProvince: province,
          receiverCity: city,
          receiverArea: area,
          receiverAddress: address
        };
        this.csqObj = {
          receiverProvince: provinceName,
          receiverCity: cityName,
          receiverArea: areaName
        };
      });
    },
    getYueyaReceiveData() {
      const diagnosisParams = this.commonCaseDetail.detail;
      PublicAddressDetailCase(get(diagnosisParams, "id")).then(res => {
        this.yueyaRefType = res.refType;
        const {
          receiveUser,
          phone,
          province,
          city,
          area,
          address,
          provinceName,
          areaName,
          cityName,
          contactWay,
          phoneArea
        } = res;
        this.params = {
          ...this.params,
          receiverName: receiveUser,
          receiverMobile: phone,
          receiverContactWay: contactWay,
          receiverPhoneArea: phoneArea,
          receiverProvince: province,
          receiverCity: city,
          receiverArea: area,
          receiverAddress: address,
          contactWay
        };
        this.csqObj = {
          receiverProvince: provinceName,
          receiverCity: cityName,
          receiverArea: areaName
        };
      });
    }
  },
  created() {
    getDictList("logistics_company").then(data => {
      const obj = {};
      this.companyList = data.map(item => {
        obj[item.key] = item.value;
        return {
          key: item.key,
          value: item.value
        };
      });
      this.companyObj = obj;
    });
    const diagnosisParams = this.commonCaseDetail.detail;
    if (get(diagnosisParams, "id")) {
      let isMaintain =
        this.refType === "3" ? "modelLogisticsIdRemain" : "modelLogisticsId";
      if (this.isReissueFlag) {
        isMaintain = sessionStorage.getItem("logisticsId");
      } else {
        isMaintain =
          get(diagnosisParams.curePlanInfo, isMaintain) ||
          sessionStorage.getItem("logisticsId");
      }
      this.modelDeliverFlag = !!isMaintain ? "1" : "0";
      if (this.$route.query.productType == "F") {
        this.getYueyaReceiveData();
      } else {
        if (this.refType === "3") {
          this.getCaseDoneDetail();
        }
      }
      if (isMaintain) {
        logisticsDetail(isMaintain).then(data => {
          const { logisticsNo, companyCode, logisticsDatetime, id } = data;
          if (data.way == "2") {
            this.modelDeliverFlag = "0";
            this.params.id = id;
            return;
          }
          this.params.logisticsNo = logisticsNo || "";
          this.params.companyCode = companyCode || "";
          this.companyCode = this.companyObj[companyCode] || "";
          this.params.logisticsDatetime = logisticsDatetime || "";
          this.params.id = id;
        });
      }
    }
  },
  components: {
    FullLoading
  }
};
</script>

<style scoped lang="scss">
.appliance {
  margin-left: 1.3rem;
  font-size: 0.14rem;
  color: #d9001b;
}

.the_delivery {
  height: calc(100% - 58px);
  padding: 20px 30px;
  position: relative;
  padding-top: 0;

  .title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .scheduling_con {
    background-color: #fff;
    border-radius: 0.1rem;

    .s_con_form {
      margin-bottom: 0.36rem;

      .f_single {
        display: flex;
        align-items: center;
        margin-bottom: 0.3rem;

        .f_left {
          min-width: 1rem;
          margin-right: 0.3rem;
          font-size: 0.14rem;
          text-align: right;
          position: relative;
        }

        .required {
          &:before {
            content: "*";
            color: red;
            font-size: 0.16rem;
            transform: translate(50%, -50%);
          }
        }

        .f_left_dz {
          &:before {
            transform: translateY(-10%);
          }
        }

        .f_right {
          flex: 1;
          position: relative;

          select,
          input {
            box-sizing: border-box;
            width: 3.16rem;
            height: 40px;
            padding: 10px 20px;
            background: #f4f4f4;
            border-radius: 4px;
            border: 1px solid #bfbfbf;
          }

          .not_dis {
            background: #fff;
          }

          .f_r_p {
            padding: 10px 20px;
            width: 3.16rem;
            min-height: 40px;
            display: flex;
            line-height: 20px;
            align-items: center;
            box-sizing: border-box;
            background: #f4f4f4;
            border-radius: 4px;
            border: 1px solid #bfbfbf;
          }

          .time_sp {
            color: #3e3e3e;
            font-size: 0.2rem;
            margin: 0 0.1rem;
          }

          .select_box {
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;

            select {
              flex: 1;
            }
          }
        }
      }
    }

    .foo_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    .foo_btn_dis {
      background-color: #e5e5e5;
      display: flex !important;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      outline: none !important;
      border: none;
      border-radius: 6px;
      color: #ffffff;
    }
  }
}
</style>
